<template>
  <div>

    <b-row class="match-height">

      <!-- Connected Locations-->
      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-bolder"
                    icon="MapPinIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Connected Locations
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  class="text-primary cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  @click="$emit('toggleEditSideBar')"
              />

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50"
                style="max-height: 315px"
            >
              <ul class="list-group list-group-flush">

                <li v-for="index in [1,2,3,4,5,6,7,8,9,10]" class="list-group-item font-black">Tottenham Hale Medical
                  Practice
                  <br>
                  <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                </li>

              </ul>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-col>


      <!-- Leaves-->
      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="CoffeeIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Leaves
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <feather-icon
                  v-b-tooltip.hover.top="'Add'"
                  @click="$emit('toggleLeavesSideBar')"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="18"
              />

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
                style="max-height: 315px"
            >
              <ul class="list-group list-group-flush">
                <li class="list-group-item font-black pr-0" v-for="item in [1,2,3,4,5,6,7,8,9,10]">Tottenham Hale Medical Practice

                  <!-- Dropdown -->
                  <DropDownMenu/>

                  <br>
                  <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                </li>
              </ul>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-col>


      <!--      Documents-->
      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Documents
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <feather-icon
                  v-b-tooltip.hover.top="'Download all!'"
                  class="text-primary cursor-pointer"
                  icon="DownloadIcon"
                  size="18"
              />

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50"
                style="max-height: 315px"
            >
              <ul class="list-group list-group-flush">
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/excel.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/excel.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/excel.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>
                <li class="list-group-item font-black pr-0">
                  <b-media no-body>
                    <b-img
                        :src="require('@/assets/images/filetypes/pdf.png')"
                        alt="browser img"
                        class="mr-1 "
                        height="30"
                    />
                    <h6 class="align-self-center my-auto font-weight-bolder">
                      Biochemistry Certification
                      <br>
                      <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                    </h6>

                  </b-media>
                </li>

              </ul>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import DropDownMenu from "@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BImg,
  BLink,
  BMedia,
  BRow,
  VBTooltip
} from 'bootstrap-vue'


export default {
  components: {
    DropDownMenu,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
