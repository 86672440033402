<template>
    <div class="dropdown float-right mt-1">
      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-row >
          <b-col class="mt-n1 ml-2">
             <b-button
                    size="23"
                    variant="outline-white"
                    class="btn-icon bg-white text-primary ml-2 mt-n5 "
                  >
                    <feather-icon icon="CheckSquareIcon" size="18" />
                  </b-button>

                    <b-button
                    size="23"
                    variant="outline-white"
                    class="btn-icon bg-white text-danger ml-1 mt-n5"
                  >
                    <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
                  </b-button>
          </b-col>
        </b-row>
        
      </b-dropdown>
    </div>

</template>

<script>
import {BDropdown, BDropdownItem, BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    BCol,
    BRow,
    BButton,
  }
}
</script>
